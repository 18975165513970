import Vue from 'vue'
import Router from 'vue-router'

Vue.use( Router );

import Home from './views/Home';
import Athena from './views/Athena';
import Dee from './views/Dee';
import Jenny from './views/Jenny';


export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home
        },
        {
            path: '*',
            redirect: Home
        },
        {
            path: '/athena',
            component: Athena
        },
        {
            path: '/jenny',
            component: Jenny
        },
        {
            path: '/dee',
            component: Dee
        }
    ]
});
