<template>
    <div class="jenny-relative">
        <button @click="[startProgram(1), windowClicked = true]" v-if="!windowClicked" class="absolute-vh-centered ns-btn" style="z-index: 2000;">Start</button>
        <div 
            class="fullscreen-btn"
            :style="{ opacity: opacity }"
        >
            <audio 
                :src="currentAudio"
                playsinline
                preload
                muted
                @ended="playNextAudio"
                ref="audio"
                
            ></audio>
            <p class="absolute-vh-centered text" :style="{ opacity: opacity }">{{ currentText }}</p>


        </div>
        <Interstitial :active="programEnded && true" />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Interstitial from '../components/Interstitial';

import { rootUrl, jennyAudio } from '../url-paths';

export default {

    components: {
        Interstitial
    },

    computed: {
        character () {
            return this.$store.state.character;
        },

        opacity () {
            return this.character === 'jenny' ? 1 : 0
        },

        programEnded () {
            return this.$store.state.programEnded
        },

        resetProgram () {
            return this.$store.state.resetProgram;
        }
    },

    watch: {
        character (character) {
            if (character !== 'jenny') {
                this.pauseAudio ();
            } else {
                this.playAudio ();
            }
        },

        programEnded (boolean) {
            if (boolean) {
                this.emptyProgramData ();
            }
        },

        resetProgram (bool) {
            if (bool) {
                this.reset ();
            }
        }
    },

    data () {
        return {
            index: 0,
            rootUrl,
            audio: jennyAudio,
            currentAudio: '',
            currentText: '',
            windowClicked: false,
            audioPromise: null
        }
    },

    methods: {
        ...mapMutations({
            changeCharacter: 'changeCharacter',
            startProgram: 'startProgram',
            programReset: 'programReset'
        }),

        playAudio () {
            const audioPromise = this.$refs.audio.play ();
            this.audioPromise = audioPromise;
        },

        pauseAudio () {
            
            if (this.audioPromise !== undefined) {
                this.audioPromise.then(() => {
                    this.$refs.audio.pause ();
                }).catch(() => {
                    
                    this.reset ();
                   
                })
            }
        
        },

        playNextAudio () {
            this.index = this.index + 1;
            this.randomAudio ();

            if (this.index === 1) {
                this.$nextTick (() => {
                    setTimeout(() => {
                        this.playAudio ();
                    }, 1000)
                });
                
                return;
            } 

            this.changeCharacter('dee');
        },

        randomAudio () {
            const randomPath = this.audio[Math.floor(Math.random()*this.audio.length)];
            this.currentAudio = this.rootUrl + randomPath.src;
            this.currentText = randomPath.text;
        },

        emptyProgramData () {

            this.index = 0;

        },

        reset () {
            this.index = 0;
            this.$refs.audio.pause ();
            this.programReset(false);
            if (this.character !== 'athena') this.changeCharacter('athena');
        }

    },

    mounted () {
        this.randomAudio ();
    }
}
</script>

<style>

.jenny-relative {
    width: 100vw;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    background-color: #0f0;
    overflow: hidden;
}

.fullscreen-btn {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(3, 3, 187);
}



</style>
