import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import createMutationsSharer from "vuex-shared-mutations";


//INITIAL STATE
const state = () => ({
    character: 'athena',
    programStart: [false, false, false],
    programShouldStart: false,
    programEnded: false,
    resetProgram: false
})


//MUTATIONS
const mutations = {
    changeCharacter (state, character) {
        state.character = character;
    },

    startProgram (state, index) {
        state.programStart[index] = true;

        const eachVideoHasBeenClicked = state.programStart.every(piece => piece === true);

        if (eachVideoHasBeenClicked) state.programShouldStart = true;
    },

    endProgram (state, boolean) {
        state.programEnded = boolean;
    },

    programReset (state, boolean) {
        state.resetProgram = boolean;
    }
}

//ACTIONS
const actions = {
    // increment (state) {
    //     state.count = state.count + 1;
    // }
}


//EXPORT
export default new Vuex.Store({
	state,
	actions,
    mutations,
    plugins: [createMutationsSharer({ predicate: ["changeCharacter", "endProgram", "startProgram", "programReset"] })]
});
