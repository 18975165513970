<template>
    <div class="homepage">
        <div class="password-modal" v-if="showPasswordModal">
            <form @submit.prevent="submitPassword">
                <input type="password" placeholder="Enter Password" :value="password" @input="(e) => password = e.target.value" />
                <button type="submit">Submit</button>
            </form>
        </div>
        <NSLogo />
        <div class="btn-container">
            <!-- <button @click="popups">Open Neural Swamp</button> -->
            <button @click="jennyPopup">Open Jenny</button>
            <button @click="athenaPopup">Open Athena</button>
            <button @click="deePopup">Open Dee</button>
        </div>
    </div>
</template>

<script>
import NSLogo from '../components/NSLogo.vue'
export default {
    components: {
        NSLogo
    },
    data () {
        return {
            password: '',
            correctPassword: 'so-martinesyms',
            showPasswordModal: true
        }
    },

    methods: {
        submitPassword () {

            if (this.password !== this.correctPassword) {
                console.log('in here');
                this.password = '';
                return;
            }
            console.log('password correct');
            this.showPasswordModal = false;
        },

        jennyPopup () {
            setTimeout(() => {
                window.open('/jenny', 'Martine Syms, Neural Swamp, 2021 — Jenny', "width="+1920+",height="+1080+",scrollbars=yes,toolbar=no" );
            }, 1000)
            return false;
        },
        athenaPopup () {
            setTimeout(() => {
                window.open('/athena', 'Martine Syms, Neural Swamp, 2021 — Athena', "width="+1080+",height="+1920+",scrollbars=yes,toolbar=no" );
            }, 1000)
            
        },
        deePopup () {
            setTimeout(() => {
                window.open('/dee', 'Martine Syms, Neural Swamp, 2021 — Dee', "width="+1080+",height="+1920+",scrollbars=yes,toolbar=no" );
            }, 1000)
        },
        popups () {
            setTimeout(() => {
                window.open('/jenny', 'Martine Syms, Neural Swamp, 2021 — Jenny', "width="+1920+",height="+1080+",scrollbars=yes,toolbar=no" );
            }, 500)
            setTimeout(() => {
                window.open('/athena', 'Martine Syms, Neural Swamp, 2021 — Athena', "width="+1080+",height="+1920+",scrollbars=yes,toolbar=no" );
            }, 500)
            setTimeout(() => {
                window.open('/dee', 'Martine Syms, Neural Swamp, 2021 — Dee', "width="+1080+",height="+1920+",scrollbars=yes,toolbar=no" );
            }, 500)
        }
    }
}
</script>

<style scoped>
.homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.password-modal {
  position: fixed;
  top: 0; left: 0;
  width: 100vw;
  height: 100vh;
  z-index:100000;
  background-color: rgb(3, 3, 187);
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-modal form {
  width: 100%;
  max-width: 600px;
  color: #0f0;
  padding: 0 1rem;
}

.password-modal form input:focus {
  outline: none;
}

.password-modal form input {
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #0f0;
  color: #0f0;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
}

.password-modal form input::placeholder {
  color: #0f0;
  text-transform: uppercase;
}

.password-modal form button {
  height: 48px;
  width: 100%;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  color: #0f0;
  cursor: pointer;
  font-family: 'Daydream';
  margin-top: 0rem;
}

.password-modal form button:hover {
    opacity: 0.7;
}

.btn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    /* width: 100%; */
}

button {
    margin-top: 2rem;
    font-family: 'Daydream';
    text-transform: uppercase;
    color: rgb(3, 3, 187);
    background-color: #0f0;
    font-size: 1rem;
    width: 16rem;
    padding: 1rem 2rem;
    cursor: pointer;
    border: 1px solid #0f0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

button:hover {
    background-color: rgb(3, 3, 187);
    color: #0f0;
}
</style>
