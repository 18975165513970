export const rootUrl = 'https://neural-swamp.special---offer.com/';

export const athenaVideos	= [
'athena/video/01_417_1.mp4',
'athena/video/01_419_1.mp4',
'athena/video/01_428_1.mp4',
'athena/video/01_432_1.mp4',
'athena/video/01_444_1.mp4',
'athena/video/01_446_1.mp4',
'athena/video/01_456_1.mp4',
'athena/video/01_458_1.mp4',
'athena/video/01_460_1.mp4',
'athena/video/01_469_1.mp4',
'athena/video/01_471_1.mp4',
'athena/video/01_473_1.mp4',
'athena/video/01_486_1.mp4',
'athena/video/01_498_1.mp4',
'athena/video/01_500_1.mp4',
'athena/video/01_512_1.mp4',
'athena/video/01_528_1.mp4',
'athena/video/01_537_1.mp4',
'athena/video/01_541_1.mp4',
'athena/video/01_553_1.mp4',
'athena/video/01_563_1.mp4',
'athena/video/01_567_1.mp4',
'athena/video/01_580_1.mp4',
'athena/video/01_582_1.mp4',
'athena/video/01_592_1.mp4',
'athena/video/01_594_1.mp4',
'athena/video/01_605_1.mp4',
'athena/video/01_609_1.mp4',
'athena/video/01_615_1.mp4',
'athena/video/01_621_1.mp4',
'athena/video/01_635_1.mp4',
'athena/video/01_639_1.mp4',
'athena/video/01_646_1.mp4',
'athena/video/01_654_1.mp4',
'athena/video/01_663_1.mp4',
'athena/video/01_669_1.mp4',
'athena/video/01_681_1.mp4',
'athena/video/01_736_1.mp4',
'athena/video/01_738_1.mp4',
'athena/video/01_743_1.mp4',
'athena/video/01_747_1.mp4',
'athena/video/01_774_1.mp4',
'athena/video/01_782_1.mp4',
'athena/video/01_788_1.mp4',
'athena/video/01_811_1.mp4',
'athena/video/01_827_1.mp4',
'athena/video/01_841_1.mp4',
'athena/video/01_928_1.mp4',
'athena/video/01_940_1.mp4',
'athena/video/01_963_1.mp4'
];

export const athenaBRoll 	= [
'athena/b-roll/01_414_1.mp4',
'athena/b-roll/01_416_1.mp4',
'athena/b-roll/01_418_1.mp4',
'athena/b-roll/01_550_1.mp4',
'athena/b-roll/01_552_1.mp4',
'athena/b-roll/01_554_1.mp4',
'athena/b-roll/01_697_1.mp4',
'athena/b-roll/01_699_1.mp4',
'athena/b-roll/01_701_1.mp4',
'athena/b-roll/01_910_1.mp4',
'athena/b-roll/01_912_1.mp4',
'athena/b-roll/01_914_1.mp4',
'athena/b-roll/01_970_1.mp4',
'athena/b-roll/01_974_1.mp4'
];

export const athenaAudio 	= [
'athena/audio/0-000.wav',
'athena/audio/0-001.wav',
'athena/audio/0-002.wav',
'athena/audio/0-003.wav',
'athena/audio/0-004.wav',
'athena/audio/0-005.wav',
'athena/audio/0-006.wav',
'athena/audio/0-007.wav',
'athena/audio/0-008.wav',
'athena/audio/0-009.wav',
'athena/audio/4-000.wav',
'athena/audio/4-001.wav',
'athena/audio/4-002.wav',
'athena/audio/4-003.wav',
'athena/audio/4-004.wav',
'athena/audio/4-005.wav',
'athena/audio/4-006.wav',
'athena/audio/4-007.wav',
'athena/audio/4-008.wav',
'athena/audio/4-009.wav',
'athena/audio/6-000.wav',
'athena/audio/6-001.wav',
'athena/audio/6-002.wav',
'athena/audio/6-003.wav',
'athena/audio/6-004.wav',
'athena/audio/6-005.wav',
'athena/audio/6-006.wav',
'athena/audio/6-007.wav',
'athena/audio/6-008.wav',
'athena/audio/6-009.wav',
'athena/audio/8-000.wav',
'athena/audio/8-001.wav',
'athena/audio/8-002.wav',
'athena/audio/8-003.wav',
'athena/audio/8-004.wav',
'athena/audio/8-005.wav',
'athena/audio/8-006.wav',
'athena/audio/8-007.wav',
'athena/audio/8-008.wav',
'athena/audio/8-009.wav',
'athena/audio/10-000.wav',
'athena/audio/10-001.wav',
'athena/audio/10-002.wav',
'athena/audio/10-003.wav',
'athena/audio/10-004.wav',
'athena/audio/10-005.wav',
'athena/audio/10-006.wav',
'athena/audio/10-007.wav',
'athena/audio/10-008.wav',
'athena/audio/10-009.wav',
'athena/audio/12-000.wav',
'athena/audio/12-001.wav',
'athena/audio/12-002.wav',
'athena/audio/12-003.wav',
'athena/audio/12-004.wav',
'athena/audio/12-005.wav',
'athena/audio/12-006.wav',
'athena/audio/12-007.wav',
'athena/audio/12-008.wav',
'athena/audio/12-009.wav',
'athena/audio/14-000.wav',
'athena/audio/14-001.wav',
'athena/audio/14-002.wav',
'athena/audio/14-003.wav',
'athena/audio/14-004.wav',
'athena/audio/14-005.wav',
'athena/audio/14-006.wav',
'athena/audio/14-007.wav',
'athena/audio/14-008.wav',
'athena/audio/14-009.wav',
'athena/audio/16-000.wav',
'athena/audio/16-001.wav',
'athena/audio/16-002.wav',
'athena/audio/16-003.wav',
'athena/audio/16-004.wav',
'athena/audio/16-005.wav',
'athena/audio/16-006.wav',
'athena/audio/16-007.wav',
'athena/audio/16-008.wav',
'athena/audio/16-009.wav',
'athena/audio/18-000.wav',
'athena/audio/18-001.wav',
'athena/audio/18-002.wav',
'athena/audio/18-003.wav',
'athena/audio/18-004.wav',
'athena/audio/18-005.wav',
'athena/audio/18-006.wav',
'athena/audio/18-007.wav',
'athena/audio/18-008.wav',
'athena/audio/18-009.wav',
'athena/audio/20-000.wav',
'athena/audio/20-001.wav',
'athena/audio/20-002.wav',
'athena/audio/20-003.wav',
'athena/audio/20-004.wav',
'athena/audio/20-005.wav',
'athena/audio/20-006.wav',
'athena/audio/20-007.wav',
'athena/audio/20-008.wav',
'athena/audio/20-009.wav'
];

export const deeVideos = [
'dee/video/01_21_1.mp4',
'dee/video/01_32_1.mp4',
'dee/video/01_44_1.mp4',
'dee/video/01_68_1.mp4',
'dee/video/01_76_1.mp4',
'dee/video/01_83_1.mp4',
'dee/video/01_89_1.mp4',
'dee/video/01_95_1.mp4',
'dee/video/01_109_1.mp4',
'dee/video/01_158_1.mp4',
'dee/video/01_165_1.mp4',
'dee/video/01_173_1.mp4',
'dee/video/01_179_1.mp4',
'dee/video/01_181_1.mp4',
'dee/video/01_185_1.mp4',
'dee/video/01_187_1.mp4',
'dee/video/01_197_1.mp4',
'dee/video/01_204_1.mp4',
'dee/video/01_207_1.mp4',
'dee/video/01_219_1.mp4',
'dee/video/01_233_1.mp4',
'dee/video/01_239_1.mp4',
'dee/video/01_249_1.mp4',
'dee/video/01_251_1.mp4',
'dee/video/01_259_1.mp4',
'dee/video/01_261_1.mp4',
'dee/video/01_265_1.mp4',
'dee/video/01_275_1.mp4',
'dee/video/01_277_1.mp4',
'dee/video/01_286_1.mp4',
'dee/video/01_290_1.mp4',
'dee/video/01_297_1.mp4',
'dee/video/01_306_1.mp4',
'dee/video/01_307_1.mp4',
'dee/video/01_314_1.mp4',
'dee/video/01_315_1.mp4',
'dee/video/01_324_1.mp4',
'dee/video/01_326_1.mp4',
'dee/video/01_332_1.mp4',
'dee/video/01_341_1.mp4',
'dee/video/01_343_1.mp4',
'dee/video/01_351_1.mp4',
'dee/video/01_359_1.mp4',
'dee/video/01_366_1.mp4',
'dee/video/01_374_1.mp4',
'dee/video/01_378_1.mp4',
'dee/video/01_386_1.mp4',
'dee/video/01_390_1.mp4',
'dee/video/01_401_1.mp4',
'dee/video/01_405_1.mp4'
];


export const deeBRoll = [
'dee/b-roll/01_3_1.mp4',
'dee/b-roll/01_6_1.mp4',
'dee/b-roll/01_8_1.mp4',
'dee/b-roll/01_159_1.mp4',
'dee/b-roll/01_161_1.mp4',
'dee/b-roll/01_163_1.mp4',
'dee/b-roll/01_178_1.mp4',
'dee/b-roll/01_180_1.mp4',
'dee/b-roll/01_182_1.mp4',
'dee/b-roll/01_287_1.mp4',
'dee/b-roll/01_289_1.mp4',
'dee/b-roll/01_291_1.mp4'
];

export const deeAudio 		= [
'dee/audio/3-001.wav',
'dee/audio/3-002.wav',
'dee/audio/3-003.wav',
'dee/audio/3-004.wav',
'dee/audio/3-005.wav',
'dee/audio/3-006.wav',
'dee/audio/3-007.wav',
'dee/audio/3-008.wav',
'dee/audio/3-009.wav',
'dee/audio/3.wav',
'dee/audio/5-001.wav',
'dee/audio/5-002.wav',
'dee/audio/5-003.wav',
'dee/audio/5-004.wav',
'dee/audio/5-005.wav',
'dee/audio/5-006.wav',
'dee/audio/5-007.wav',
'dee/audio/5-008.wav',
'dee/audio/5-009.wav',
'dee/audio/5.wav',
'dee/audio/7-001.wav',
'dee/audio/7-002.wav',
'dee/audio/7-003.wav',
'dee/audio/7-004.wav',
'dee/audio/7-005.wav',
'dee/audio/7-006.wav',
'dee/audio/7-007.wav',
'dee/audio/7-008.wav',
'dee/audio/7-009.wav',
'dee/audio/7.wav',
'dee/audio/9-001.wav',
'dee/audio/9-002.wav',
'dee/audio/9-003.wav',
'dee/audio/9-004.wav',
'dee/audio/9-005.wav',
'dee/audio/9-006.wav',
'dee/audio/9-007.wav',
'dee/audio/9-008.wav',
'dee/audio/9-009.wav',
'dee/audio/9.wav',
'dee/audio/11-001.wav',
'dee/audio/11-002.wav',
'dee/audio/11-003.wav',
'dee/audio/11-004.wav',
'dee/audio/11-005.wav',
'dee/audio/11-006.wav',
'dee/audio/11-007.wav',
'dee/audio/11-008.wav',
'dee/audio/11-009.wav',
'dee/audio/11.wav',
'dee/audio/13-001.wav',
'dee/audio/13-002.wav',
'dee/audio/13-003.wav',
'dee/audio/13-004.wav',
'dee/audio/13-005.wav',
'dee/audio/13-006.wav',
'dee/audio/13-007.wav',
'dee/audio/13-008.wav',
'dee/audio/13-009.wav',
'dee/audio/13.wav',
'dee/audio/15-001.wav',
'dee/audio/15-002.wav',
'dee/audio/15-003.wav',
'dee/audio/15-004.wav',
'dee/audio/15-005.wav',
'dee/audio/15-006.wav',
'dee/audio/15-007.wav',
'dee/audio/15-008.wav',
'dee/audio/15-009.wav',
'dee/audio/15.wav',
'dee/audio/17-001.wav',
'dee/audio/17-002.wav',
'dee/audio/17-003.wav',
'dee/audio/17-004.wav',
'dee/audio/17-005.wav',
'dee/audio/17-006.wav',
'dee/audio/17-007.wav',
'dee/audio/17-008.wav',
'dee/audio/17-009.wav',
'dee/audio/17.wav',
'dee/audio/19-001.wav',
'dee/audio/19-002.wav',
'dee/audio/19-003.wav',
'dee/audio/19-004.wav',
'dee/audio/19-005.wav',
'dee/audio/19-006.wav',
'dee/audio/19-007.wav',
'dee/audio/19-008.wav',
'dee/audio/19-009.wav',
'dee/audio/19.wav',
'dee/audio/21-001.wav',
'dee/audio/21-002.wav',
'dee/audio/21-003.wav',
'dee/audio/21-004.wav',
'dee/audio/21-005.wav',
'dee/audio/21-006.wav',
'dee/audio/21-007.wav',
'dee/audio/21-008.wav',
'dee/audio/21-009.wav',
'dee/audio/21.wav',
'dee/audio/23-001.wav',
'dee/audio/23-002.wav',
'dee/audio/23-003.wav',
'dee/audio/23-004.wav',
'dee/audio/23-005.wav',
'dee/audio/23-006.wav',
'dee/audio/23-007.wav',
'dee/audio/23-008.wav',
'dee/audio/23-009.wav',
'dee/audio/23.wav'
];

export const jennyAudio = [
{ src: 'jenny/audio/1-001.wav', text: "She wants you to stop fucking with me." },
{ src: 'jenny/audio/1-002.wav', text: "I didn't have to." },
{ src: 'jenny/audio/1-003.wav', text: "I really wanted to check on tonight. I need a man next hand." },
{ src: 'jenny/audio/1-004.wav', text: "I can't say I'm not good at what I do." },
{ src: 'jenny/audio/1-005.wav', text: "Why don't you leave with me? You miss me? You missed all my work. I missed all my friends. I missed all my family. I missed all my friends and I miss." },
{ src: 'jenny/audio/1-006.wav', text: "Smiles here. Do you understand?" },
{ src: 'jenny/audio/1-007.wav', text: "They sit down and kiss. I didn't want to have my life ruined by you seeing you. I felt like a fucking freaking child." },
{ src: 'jenny/audio/1-008.wav', text: "I mean, I really hate it when you convince me of" },
{ src: 'jenny/audio/1-009.wav', text: "Hi sick ray" },
{ src: 'jenny/audio/1.wav', text: "Jenny appears from behind a chair." },
{ src: 'jenny/audio/2-001.wav', text: "Yeah I'm not fucking with a view. You wanna fuck my ass will do." },
{ src: 'jenny/audio/2-002.wav', text: "Dee I'm sorry that I was so afraid of you. I don't want to talk to you. I want to make you aware of my" },
{ src: 'jenny/audio/2-003.wav', text: "No I mean that was a few minutes ago and I told you I'm in Tokyo? It's a different country." },
{ src: 'jenny/audio/2-004.wav', text: "You don't question the process. You just try to do it for what?" },
{ src: 'jenny/audio/2-005.wav', text: "Fuck You!" },
{ src: 'jenny/audio/2-006.wav', text: "Fuck you! She takes a long breath, then let's it out no." },
{ src: 'jenny/audio/2-007.wav', text: "She slowly gets down from the screen and kisses him. I got to stop." },
{ src: 'jenny/audio/2-008.wav', text: "Well I am not gonna change. I am not gonna go back to better life. I am gonna do what I did." },
{ src: 'jenny/audio/2-009.wav', text: "You don't like me." },
{ src: 'jenny/audio/2.wav', text: "Done now, I don't want to ever." },
{ src: 'jenny/audio/22-001.wav', text: "You're going to explain what it is." },
{ src: 'jenny/audio/22-002.wav', text: "Do you get a bottle of wine or something you bet I am." },
{ src: 'jenny/audio/22-003.wav', text: "You just scoff at me. You do whatever you want to me. You do whatever you want." },
{ src: 'jenny/audio/22-004.wav', text: "I don't know anybody." },
{ src: 'jenny/audio/22-005.wav', text: "Don't want to be misunderstood." },
{ src: 'jenny/audio/22-006.wav', text: "Why don't you give me the one?" },
{ src: 'jenny/audio/22-007.wav', text: "Open up wide. Will help open up. Cause you are keeping me open." },
{ src: 'jenny/audio/22-008.wav', text: "I didn't see the light." },
{ src: 'jenny/audio/22-009.wav', text: "Dee sigghs and puts her hand out. You want to go for some coffee?" },
{ src: 'jenny/audio/22.wav', text: "We're going to need a hug." }
];