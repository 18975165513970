<template>
    <div class="relative">
        <button @click="[startProgram(2), windowClicked = true]" v-if="!windowClicked" class="absolute-vh-centered ns-btn" style="z-index: 2000;">Start</button>

        <div 
        >
            <div>
                <video 
                    class="real-video"
                    :src="currentVideo"
                    :key="currentVideo"
                    playsinline
                    preload
                    muted
                    ref="video"
                    :style="{ opacity: opacity }"
                    :controls="false"
                ></video>
                <audio 
                    ref="audio" 
                    :src="currentAudio" 
                    :key="currentAudio"
                    preload 
                    @ended="playNextVideo"
                ></audio>
            </div>

            <video 
                class="b-roll"
                :src="currentBRoll"
                playsinline
                preload
                muted
                loop
                ref="bRoll"
                :style="{ opacity: bRollOpacity }"
                :controls="false"
            >
            </video>

        </div>

        <Interstitial :active="programEnded && true" />
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

import { rootUrl, deeVideos, deeBRoll, deeAudio } from '../url-paths';

import Interstitial from '../components/Interstitial';

export default {

    components: {
        Interstitial
    },

    computed: {
        character () {
            return this.$store.state.character;
        },

        opacity () {
            return this.character === 'dee' ? 1 : 0
        },

        bRollOpacity () {
            return this.character !== 'dee' ? 1 : 0
        },

        programEnded () {
            return this.$store.state.programEnded
        },

        programShouldStart () {
            return this.$store.state.programShouldStart;
        },


        resetProgram () {
            return this.$store.state.resetProgram;
        }
    },

    watch: {

        character (character) {
            if (character !== 'dee') {
                this.pauseVideo ();
                this.pauseAudio ();
            } else {
                this.randomBRollVideo ();
                this.determinePlaybackRate ();
                this.playVideo ();
                this.playAudio ();
            }
        },

        programShouldStart (boolean) {
            if (boolean) {

                this.playBRoll ();

            }
        },

        programEnded (boolean) {
            if (boolean) {
                this.emptyProgramData ();
            }
        },

        resetProgram (bool) {
            if (bool) {
                this.reset ();
            }
        }
    },

    data () {
        return {
            index: 0,
            videos: deeVideos,
            bRoll: deeBRoll,
            rootUrl,
            audio: deeAudio,
            currentVideo: '',
            currentAudio: '',
            currentBRoll: '',
            currentVideoDuration: 1,
            currentAudioDuration: 1,
            windowClicked: false,
            playPromise: null,
            audioPromise: null
        }
    },

    methods: {
        ...mapMutations({
            changeCharacter: 'changeCharacter',
            startProgram: 'startProgram',
            endProgram: 'endProgram',
            programReset: 'programReset'
        }),

        playVideo () {
            const playPromise = this.$refs.video.play ()
            this.playPromise = playPromise;
        },

        pauseVideo () {
            if (this.playPromise && this.playPromise !== undefined) {
                this.playPromise.then(() => {
                    this.$refs.video.pause ();
                }).catch(() => {
                    setTimeout(() => {
                        this.programReset ();
                    }, 8000);
                })
            }
        },

        playBRoll () {
            this.$refs.bRoll.play ();
            this.$refs.bRoll.autoplay = true;
        },

        playAudio () {
            const audioPromise = this.$refs.audio.play ();
            this.audioPromise = audioPromise;
        },

        pauseAudio () {
            if (this.audioPromise && this.audioPromise !== undefined) {
                this.audioPromise.then(() => {
                    this.$refs.audio.pause ();
                }).catch(() => {
                    
                    this.programReset ();
                    
                })
            }
        },

        playNextVideo () {

            this.index = this.index + 1;

            if (this.index === 18) return this.endProgram (true);

            this.randomVideo ();
            this.randomAudio ();
            

            this.changeCharacter ('athena');

        },

        emptyProgramData () {
            
            this.index = 0;

            this.randomVideo ();
            this.randomAudio ();

            this.pauseAudio ();

            this.pauseVideo ();

        },

        randomVideo () {
            const randomPath = this.videos[Math.floor(Math.random()*this.videos.length)];
            this.currentVideo = this.rootUrl + randomPath;

            this.determineVideoDuration ();
        },

        randomBRollVideo () {
            const randomPath = this.bRoll[Math.floor(Math.random()*this.bRoll.length)];
            this.currentBRoll = this.rootUrl + randomPath;
        },

        randomAudio () {
            const randomPath = this.audio[Math.floor(Math.random()*this.audio.length)];
            this.currentAudio = this.rootUrl + randomPath;

            this.determineAudioDuration ();
        },

        determineAudioDuration () {

            this.$nextTick (() => {
                this.$refs.audio.addEventListener('loadedmetadata', () => {
                    this.currentAudioDuration = this.$refs.audio.duration;
                    console.log(this.$refs.audio.duration);
                    this.determinePlaybackRate ();
                });
            });
            
        },

        determineVideoDuration () {
            this.$nextTick(() => {
                this.$refs.video.addEventListener('loadedmetadata', () => {
                    this.currentVideoDuration = this.$refs.video.duration;
                    console.log(this.currentVideoDuration)
                });
            });
        },

        determinePlaybackRate () {
            const maxPlaybackRate = 16;
            const minPlaybackRate = 0.0625;
            const playbackRate = this.currentVideoDuration / this.currentAudioDuration;
            if (playbackRate > maxPlaybackRate) return this.$refs.video.playbackRate = maxPlaybackRate;
            if (playbackRate < minPlaybackRate) return this.$refs.video.playbackRate = minPlaybackRate;
            this.$refs.video.playbackRate = playbackRate;
        },

        reset () {
            this.index = 0;
            this.programReset(false);
            if (this.character !== 'athena') this.changeCharacter('athena');
            this.randomVideo ();
            this.randomAudio ();
            this.playBRoll ();
        },

    },

    mounted () {

        this.$nextTick(() => {
            this.$refs.bRoll.pause ();
        });

        this.randomVideo ();
        this.randomAudio ();
        this.randomBRollVideo ();

    }
}
</script>
