<template>
  <div class="relative" :class="active ? 'overlay-interstitial' : 'hidden'">
    <video 
      src="https://neural-swamp.special---offer.com/golf/neural-swamp-interstitials.mp4" 
      autoplay :controls="false" playsinline :muted="true" loop
    ></video>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Interstitial',

  props: {
    active: Boolean
  },

  watch: {
    active (isActive) {
      if (isActive) {
        setTimeout(() => {
          this.endProgram (false);
          this.changeCharacter('athena')
        }, 30000)
      }
    }
  },

  methods: {
    ...mapMutations({
      endProgram: 'endProgram',
      changeCharacter: 'changeCharacter'
    }),
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.overlay-interstitial {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  width: 100vw;
  height: 100vh;
  background-color: red;
  color: black;
  font-size: 15vw;
  opacity: 1;
}

video {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
}

.hidden {
  opacity: 0;
  z-index: -1;
}
</style>
